import React from 'react';
import { useRangeDate } from '../../hooks/useRangeDate';
import { useTimecard } from './hooks/useTimecard';
import { Mobile } from './components/MobileComponent';
import { Desktop } from './components/DesktopComponent';
import TimecardModal from './components/TimecardModal';

const DailyAttendance: React.FC = () => {
  const {
    startDate,
    endDate,
    memoizedSplitDateRange,
    handleNextMonth,
    handlePreviousMonth,
  } = useRangeDate();
  
  const {
    timecardList,
    sumTotalHours,
    handleTimecardClick,
    selectedTimecard,
    handleTimecardModalClose,
    isModalOpen
  } = useTimecard(startDate, endDate);
  
  return (
    <div>
      <Mobile 
        startDate={startDate} 
        endDate={endDate} 
        timecardList={timecardList} 
        memoizedSplitDateRange={memoizedSplitDateRange} 
        handleNextMonth={handleNextMonth} 
        handlePreviousMonth={handlePreviousMonth} 
        sumTotalHours={sumTotalHours} 
        handleTimecardClick={handleTimecardClick}
      />
      <Desktop
        startDate={startDate} 
        endDate={endDate} 
        timecardList={timecardList} 
        handleNextMonth={handleNextMonth} 
        handlePreviousMonth={handlePreviousMonth} 
        sumTotalHours={sumTotalHours}
        handleTimecardClick={handleTimecardClick}
      />
      
      <TimecardModal 
        timecard={selectedTimecard} 
        isOpen={isModalOpen}
        onClose={handleTimecardModalClose}
      />
    </div>
  );
};

export default DailyAttendance;