import { TimecardWorkflowRequest, WorkflowKind, WorkflowUpdateRequest } from '../../store/types/workflow';
import { Axios } from 'axios';
class WorkflowService {
  authAxios: Axios;
  constructor(axios: Axios) {
    this.authAxios = axios;
  }
  
  async getApprovers() {
    const response = await this.authAxios.get('/workflow/approvers');
    
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
  
  async createTimecardWorkflow(data: TimecardWorkflowRequest) {
    const response = await this.authAxios.post('/workflow/timecard', data);
   
    if (response.status !== 201) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
  
  async updateTimecardWorkflow(data: WorkflowUpdateRequest) {
    const response = await this.authAxios.put('/workflow/timecard', data);
    
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
  
  async getWorkflowList(workflowType: WorkflowKind) {
    let status = 'all';
    let kind = 'sent';
    switch (workflowType) {
      case 'receivedAll':
        status = 'all';
        kind = 'received';
        break;
      case 'receivedPending':
        status = 'pending';
        kind = 'received';
        break;
      case 'receivedApproved':
        status = 'approved';
        kind = 'received';
        break;
      case 'receivedRejected':
        status = 'rejected';
        kind = 'received';
        break;
      case 'receivedReturned':
        status = 'returned';
        kind = 'received';
        break;
      case 'sentPending':
        status = 'pending';
        kind = 'sent';
        break;
      case 'sentApproved':
        status = 'approved';
        kind = 'sent';
        break;
      case 'sentRejected':
        status = 'rejected';
        kind = 'sent';
        break;
      case 'sentReturned':
        status = 'returned';
        kind = 'sent';
        break;
    }
    const response = await this.authAxios.get('/workflow', {
      params: {
        status,
        kind,
        page: 1,
        pageSize: 10,
      },
    });
    
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
}

export default WorkflowService;