import React from 'react';
import { IColumnType } from './Table';
import { TableRowCell } from './TableRowCell';

interface TableRowProps<T> {
  data: T[];
  columns: IColumnType<T>[];
}


export function TableRow<T>({ data, columns }: TableRowProps<T>): React.ReactElement {
  return (
    <>
      {data.map((item, itemIndex) => (
        <tr
          key={`table-body-${itemIndex}`}
          className={'odd:bg-secondary-25 even:bg-white cursor-auto last:rounded-br-xl last:rounded-bl-xl border'}
        >
          {columns.map((column, columnIndex) => (
            <TableRowCell
              key={`table-row-cell-${columnIndex}`}  
              item={item}
              column={column}
              itemIndex={itemIndex}
            />
          ))}
        </tr>
      ))}
    </>
  )
}