import React, { Suspense, useEffect } from 'react';
import {
  ButtonComponent,
  DetailItemComponent,
  DetailListComponent,
  DividerComponent,
  LoadingComponent,
} from '../components';
import { useAuth } from '../hooks/useAuth';
import TimecardService from '../network/api/timecard';
import useAuthAxios from '../hooks/useAuthAxios';
import { UserStatus } from '../store/types/user';
import UserService from '../network/api/user';


const Home: React.FC = () => {
  const { user } = useAuth();
  const [userStatus, setUserStatus] = React.useState<UserStatus>({
    timecardStatus: 'not_started',
    pendingApplications: 0,
    pendingApprovalApplications: 0,
  });
  const axios = useAuthAxios();
  const userService = new UserService(axios);
  const timecardService = new TimecardService(axios);
  const DateAnimationComponent = React.lazy(() => import('../components/ui/DateAnimation'));

  useEffect(() => {
    userService.userStatus()
      .then((data) => {
        setUserStatus(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleStartTimecard = () => {
    timecardService.startTimecard()
      .then(() => {
        setUserStatus((prev) => ({ ...prev, timecardStatus: 'started' }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEndTimecard = () => {
    timecardService.endTimecard()
      .then(() => {
        setUserStatus((prev) => ({ ...prev, timecardStatus: 'ended' }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <Suspense fallback={<LoadingComponent />}>
        <DateAnimationComponent />
      </Suspense>
      <DividerComponent />
      {
        user?.canUseTimecard && (
          <>
            <div className={'w-full flex justify-center gap-6 my-4'}>
              <ButtonComponent onClick={handleStartTimecard}
                               disabled={['started', 'ended'].includes(userStatus.timecardStatus)}>出勤</ButtonComponent>
              <ButtonComponent onClick={handleEndTimecard}
                               disabled={['not_started', 'ended'].includes(userStatus.timecardStatus)}>退勤</ButtonComponent>
            </div>
            <DividerComponent />
          </>
        )
      }
      <div className={'md:w-1/2 m-auto my-10'}>
        <DetailListComponent>
          <DetailItemComponent title={'申請中'}>{userStatus.pendingApplications}</DetailItemComponent>
          {
            (user?.isSuperAdmin || user?.isAdmin) && (
              <DetailItemComponent title={'承認待ち'}>{userStatus.pendingApprovalApplications}</DetailItemComponent>
            )
          }
        </DetailListComponent>
      </div>
    </div>
  );
};

export default Home;