import React, { forwardRef, ReactElement } from 'react';

interface IconButtonProps {
  icon: ReactElement;
  onClick?: () => void;
  className?: string;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({onClick, icon, className = ''}, ref) => {
  return (
    <button
      ref={ref}
      onClick={onClick}
      className={`bg-transparent ${className}`}
    >
      {icon}
    </button>
  );
});

IconButton.displayName = 'IconButton';

export default IconButton;