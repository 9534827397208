import React from 'react';

interface TabNavigationProps {
  tabs: string[];
  activeTabIndex: number;
  handleTabClick: (index: number) => void;
  position?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
}

export const TabNavigation: React.FC<TabNavigationProps> = (props) => {
  const { tabs, activeTabIndex, handleTabClick, position = 'start' } = props;

  return (
    <ul className={`overflow-x-scroll w-full flex justify-${position}`}>
      {tabs.map((tab, index) => (
        <li
          key={index}
          onClick={() => {
            handleTabClick(index);
          }}
          className={`cursor-pointer px-4 py-2 border-b-4 hover:border-primary-100 font-bold text-xl whitespace-nowrap ${activeTabIndex === index ? 'border-primary-100 text-primary-100' : 'text-secondary-100'}`}
        >
          {tab}
        </li>
      ))}
    </ul>
  );

}