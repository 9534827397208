import React from 'react';
import { Label } from './Label';

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
}

const Input = React.forwardRef( function Input(
  props: InputProps, 
  forwarderRef: React.Ref<HTMLInputElement>
)  {
  const { 
    name, 
    label,
    id, 
    className,
    ...rest
  } = props;
  
  const htmlFor = id ? id : `id_input_${name}`;
  
  return (
    <Label htmlFor={htmlFor} label={label} className={className}>
      <input {...rest} name={name} id={htmlFor} className={'outline-0 w-full disabled:opacity-50'} ref={forwarderRef} />
    </Label>
  );
});

export default Input;