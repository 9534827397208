import React from 'react';

interface LabelProps {
  children: React.ReactNode;
  htmlFor: string;
  label?: string;
  className?: string;
}

export const Label: React.FC<LabelProps> = (props) => {
  const { children, htmlFor, label, className } = props;
  
  return (
    <div
      className={'p-2 border border-primary-200 rounded-md m-2 relative bg-white ' + (className ? className : '')}
    >
      <label
        className={'text-primary-200 absolute -top-3 left-2 px-1 bg-inherit'}
        htmlFor={htmlFor}
      >
        {label}
      </label>
      {children}
    </div>
  );
};