export const MENU_ITEMS = [
  {
    title: 'ホーム',
    path: '/',
    component: 'home',
    icon: 'Home',
  },
  {
    title: '日次勤怠',
    path: '/daily-attendance',
    component: 'dailyAttendance',
    icon: 'Calendar'
  },
  {
    title: '申請',
    path: '/application',
    component: 'application',
    icon: 'ListCheck'
  }
];