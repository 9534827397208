import React, { useEffect, useState } from 'react';
import { TUser } from '../../../store/types/user';
import UserService from '../../../network/api/user';
import useAuthAxios from '../../../hooks/useAuthAxios';

export const useUserManagement = () => {
  const [users, setUsers] = useState<TUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<TUser | null>(null);
  const axios = useAuthAxios();
  const userServices = new UserService(axios);

  useEffect(() => {
    userServices.getUsers().then((data) => {
      setUsers(data);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const handleUserSelect = (user: TUser | null) => {
    setSelectedUser(user);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setSelectedUser((prev) => {
      if (prev) {
        return {
          ...prev,
          [name]: type === 'checkbox' ? checked : value,
        };
      }
      return null;
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedUser) {
      userServices.updateUser(selectedUser).then((data) => {
        setUsers((prev) => {
          const index = prev.findIndex((user) => user.id === data.id);
          if (index !== -1) {
            prev[index] = data;
          }
          return [...prev];
        });
        setSelectedUser(null);
      }).catch((error) => {
        console.error(error);
      });
    }
  };

  return {
    users,
    selectedUser,
    handleUserSelect,
    handleInputChange,
    handleSubmit,
  };
};