import axios from '../axios';
import { BACKEND_URL } from '../../store/constants/appConstants';

class AuthService {

  async checkSession() {
    const response = await fetch(`${BACKEND_URL}/session`, {
      method: 'GET',
      credentials: 'include',
      redirect: 'manual'
    });
    if (response.status === 0) {
      return { isAuthenticated: false };
    }

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return response.json();
  }

  async logout() {
    const response = await fetch(`${BACKEND_URL}/session/logout`, {
      method: 'POST',
      credentials: 'include',
      redirect: 'manual'
    });

    if (response.status === 0) {
      return true;
    }

    if (response.status !== 200) {
      throw new Error(response.statusText);
    }

    return true;
  }
}
export default AuthService;