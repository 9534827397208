import React, { useMemo } from 'react';
import { useWorkflow } from '../hooks/useWorkflw';
import { TableComponent } from '../../../components';
import { IColumnType } from '../../../components/ui/Table/Table';
import { humanReadableStatus, Workflow, WorkflowKind } from '../../../store/types/workflow';
import { WorkflowModal } from './WorkflowModal';

const allColumns: IColumnType<Workflow>[] = [
  {
    key: 'title',
    title: 'タイトル',
  },
  {
    key: 'status',
    title: 'ステータス',
    render: (_, { status }) => <span>{humanReadableStatus(status)}</span>,
  },
  {
    key: 'requesterName',
    title: '申請者',
  },
  {
    key: 'approverName',
    title: '承認者',
  },
  {
    key: 'createdAt',
    title: '日付',
  },
];


interface ApplicationListProps {
  kind: WorkflowKind;
}

export const ApplicationList: React.FC<ApplicationListProps> = ({ kind }) => {
  const { 
    workflows,
    selectedWorkflow,
    handleModalClose,
    handleWorkflowClick,
    handleWorkflowUpdate,
  } = useWorkflow(kind);

  const memorizedColumns = useMemo(() => {
    let columns = [...allColumns];
    if (kind.startsWith('received')) {
      columns = columns.filter((column) => column.key !== 'approverName');
    } else if (kind.startsWith('sent')) {
      columns = columns.filter((column) => column.key !== 'requesterName');
    }
    if (!kind.endsWith('All')) {
      columns = columns.filter((column) => column.key !== 'status');
    }
    columns[0].render = (_, workflow) => (
      <span className={'text-accent-primary font-bold cursor-pointer'} onClick={() => {handleWorkflowClick(workflow)}}>{workflow.title}</span>
    );
    return columns;
  }, [kind]);

  return (
    <div>
      <TableComponent columns={memorizedColumns} data={workflows} />
      <WorkflowModal workflow={selectedWorkflow} onClose={handleModalClose} kind={kind} handleWorkflowUpdate={handleWorkflowUpdate} />
    </div>
  );
};