import React from 'react';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';

export interface IColumnType<T> {
  key: string;
  title: string;
  width?: number;
  render?: (column: IColumnType<T>, item: T, itemIndex: number) => React.ReactNode;
}

interface TableProps<T> {
  columns: IColumnType<T>[];
  data: T[];
}

function Table<T>({ columns, data }: TableProps<T>): React.ReactElement {
  return (
    <div className={'w-full relative overflow-scroll'}>
      <table
        className={'border-collapse w-full'}
      >
        <thead>
        <TableHeader columns={columns} />
        </thead>
        <tbody>
        {
          data.length === 0 ?
            <tr>
              <td colSpan={columns.length} className={'text-center'}>No data available</td>
            </tr>
            :
            <TableRow data={data} columns={columns} />
        }
        </tbody>
      </table>
    </div>
  );
}

export default Table;