import React from 'react';
import { Auth, AuthActionType, authReducer, defaultAuth } from '../store/types/auth';

type AuthContextType = [
  Auth,
  React.Dispatch<AuthActionType>
];

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<AuthContextType | null>(null);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [auth, dispatch] = React.useReducer(authReducer, defaultAuth);
  
  return (
    <AuthContext.Provider value={[auth, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;