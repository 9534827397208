import React from 'react';
import {
  humanReadableStatus,
  humanReadableWorkflowType,
  Workflow, WorkflowKind,
  WorkflowTimecardHistory,
} from '../../../store/types/workflow';
import {
  DetailItemComponent,
  DetailListComponent,
  InputComponent,
  ModalComponent,
  TextareaComponent,
} from '../../../components';
import { convertDateToJapanese, convertDateToJapaneseWithTime } from '../../../utils/date';
import { useWorkflowModal } from '../hooks/useWorkflowModal';
import Button from '../../../components/ui/Button';

interface RequesterProps {
  history: WorkflowTimecardHistory;
  workflowType: string;
}

const Requester = ({ history, workflowType }:RequesterProps) => {
  return (
    <div className={'border p-2'}>
      <div className={'flex items-center'}>
        <div>
          <p className={'font-bold'}>申請者</p>
          <p className={'text-sm text-secondary-100'}>{convertDateToJapaneseWithTime(history?.timestamp || '')}</p>
        </div>
      </div>
      <div className={'my-1'}></div>
      {
        history.status === 'withdrawn' ?
          <p
            className={'bg-accent-primary-100 p-1 rounded-md text-white text-sm w-fit'}
          >
            {humanReadableStatus(history.status)}
          </p>
          :
          <>
            <p><b className={'text-sm'}>勤務日付：</b>{convertDateToJapanese(history?.work_date || '')}</p>
            {
              workflowType !== 'create' && workflowType !== 'delete' && history.old_start_time != '00:00' ? history.start_time !== history.old_start_time && (
                <p><b className={'text-sm'}>出勤時間：</b>{history.old_start_time} → {history.start_time}</p>
              ) :
                <p><b className={'text-sm'}>退勤時間：</b>{history.start_time}</p>
            }
            {
              workflowType !== 'create' && workflowType !== 'delete' && history.old_end_time != '00:00' ? history.end_time !== history.old_end_time && (
                <p><b className={'text-sm'}>出勤時間：</b>{history.old_end_time} → {history.end_time}</p>
              ) :
                <p><b className={'text-sm'}>退勤時間：</b>{history.end_time}</p>
            }
            <p className={'mt-2 whitespace-pre'}>{history.notes}</p>
          </>
      }
    </div>
  )
}

const Approver = (history: WorkflowTimecardHistory) => {
  return (
    <div className={'border p-2'}>
      <div className={'flex items-center justify-end'}>
        <div className={'ml-2 text-right'}>
          <p className={'font-bold'}>承認者</p>
          <p className={'text-sm text-secondary-100'}>{convertDateToJapaneseWithTime(history?.timestamp || '')}</p>
        </div>
      </div>
      <div className={'my-1'}></div>
      <div className={'flex flex-col items-end'}>
        <p
          className={`${history.status === 'approved' ? 'bg-accent-success' : history.status === 'rejected' ? 'bg-accent-primary-100' : 'bg-accent-primary'} p-1 rounded-md text-white text-sm`}
        >
          {humanReadableStatus(history.status)}
        </p>
        <p className={'mt-2'}>{history.notes}</p>
      </div>
    </div>
  )
}

interface WorkflowModalProps {
  workflow: Workflow | null;
  onClose: () => void;
  kind: WorkflowKind;
  handleWorkflowUpdate: (workflow: Workflow, history: WorkflowTimecardHistory) => void;
}

export const WorkflowModal: React.FC<WorkflowModalProps> = ({ workflow, onClose, kind, handleWorkflowUpdate }) => {
  const { handleSubmit, workflowTimecardHistory, handleInputChange } = useWorkflowModal(workflow, handleWorkflowUpdate);
  return (
    <ModalComponent
      className={'w-full'}
      isOpen={!!workflow}
      onClose={onClose}
      hasCloseBtn={true}
    >
      <div>
        {workflow && (
          <DetailListComponent>
            <DetailItemComponent title={'タイトル'}>{workflow.title}</DetailItemComponent>
            <DetailItemComponent title={'申請の種類'}>{humanReadableWorkflowType(workflow.workflowType)}</DetailItemComponent>
            <DetailItemComponent title={'ステータス'}>{humanReadableStatus(workflow.status)}</DetailItemComponent>
            {
              (kind.startsWith('received')) && (
                <DetailItemComponent title={'申請者'}>{workflow.requesterName}</DetailItemComponent>
              )
            }
            {
              (kind.startsWith('sent')) && (
                <DetailItemComponent title={'承認者'}>{workflow.approverName}</DetailItemComponent>
              )
            }
            <DetailItemComponent title={'日付'}>{workflow.createdAt}</DetailItemComponent>
          </DetailListComponent>
        )}
        { /* History box */ }
        <div className={'border-t'}>
          <div className={'p-2'}>
            <h3 className={'text-lg font-bold'}>内容</h3>
            {workflow?.history.map((history, index) => (
              history.status === 'pending' || history.status === 'withdrawn' ? <Requester key={index} history={history} workflowType={workflow?.workflowType.split('-')[1]} /> : <Approver key={index} {...history} />
            ))}
          </div>
        </div>
      </div>
      {
        (kind === 'receivedPending' || kind === 'receivedAll') && workflow?.status === 'pending' && (
          <form className={'p-2'} onSubmit={handleSubmit}>
            <TextareaComponent label={'コメント'} name={'notes'} value={workflowTimecardHistory?.notes || ''} onChange={handleInputChange}/>
            <div className={'flex justify-end gap-2'}>
              <Button type={'submit'} name={'rejected'} className={'bg-accent-primary-100'}>却下</Button>
              <Button type={'submit'} name={'returned'} className={'bg-secondary-100'}>戻す</Button>
              <Button type={'submit'} name={'approved'}>承認</Button>
            </div>
          </form>
        )
      }
      {
        (kind === 'sentReturned' || kind === 'sentAll') && workflow?.status === 'returned' && workflow.workflowType.startsWith('timecards') && (
          <form className={'p-2'} onSubmit={handleSubmit}>
            {workflow.workflowType.startsWith('timecards-create') && (
              <InputComponent label={'勤務日付'} name={'work_date'} type={'date'} value={workflowTimecardHistory?.work_date || ''} onChange={handleInputChange} />
            )}
            <InputComponent label={'出勤時間'} name={'start_time'} type={'time'} value={workflowTimecardHistory?.start_time || ''} onChange={handleInputChange} />
            <InputComponent label={'退勤時間'} name={'end_time'} type={'time'} value={workflowTimecardHistory?.end_time || ''} onChange={handleInputChange} />
            <TextareaComponent label={'コメント'} name={'notes'} value={workflowTimecardHistory?.notes || ''} onChange={handleInputChange}/>
            <div className={'flex justify-end gap-2'}>
              <Button type={'submit'} name={'withdrawn'} className={'bg-accent-primary-100'}>申請取り下げ</Button>
              <Button type={'submit'} name={'pending'}>再申請</Button>
            </div>
          </form>
        )
      }
      {(kind === 'sentPending' || kind === 'sentAll') && workflow?.status === 'pending' && (
        <form className={'p-2'} onSubmit={handleSubmit}>
          <div className={'flex justify-end gap-2'}>
            <Button type={'submit'} name={'withdrawn'} className={'bg-accent-primary-100'}>申請取下げ</Button>
          </div>
        </form>
      )}
    </ModalComponent>
  );
};