import React, { lazy, Suspense } from 'react';
import { BaseLayout } from './layouts';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MENU_ITEMS } from './store/constants/menuConstants';
import { LoadingComponent } from './components';
import { Auth, ProtectedRoute } from './auth';
import AuthProvider from './contexts/AuthContext';
import { useAuth } from './hooks/useAuth';
import Dashboard from './pages/dashboard';
import { LoadingProvider } from './contexts/LoadingContext';

const AppRoutes: React.FC = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Routes>
      <Route path={'/auth'} element={<Auth />} />
      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        <Route element={<BaseLayout />}>
          {
            MENU_ITEMS.map((item, index) => {
              const Element = lazy(() => import(`./pages/${item.component}`));
              return (
                <Route
                  key={index}
                  path={item.path}
                  element={
                    <Suspense fallback={<LoadingComponent />}>
                      <Element />
                    </Suspense>
                  }
                />
              );
            })
          }
          <Route
            path={'/dashboard'}
            element={
              user?.isAdmin || user?.isSuperAdmin ? (
                <Suspense fallback={<LoadingComponent />}>
                  <Dashboard />
                </Suspense>
              ) : (
                <h1>Not Found</h1>
              )
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <LoadingProvider>
          <AppRoutes />
        </LoadingProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
