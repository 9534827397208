import { useCallback, useContext } from 'react';
import { LoadingContext } from '../contexts/LoadingContext';
import { DECREMENT_ACTIVE_LOADING, INCREMENT_ACTIVE_LOADING, START_LOADING, STOP_LOADING } from '../store/constants/reducerConstants';

export const useLoading = () => {
  const loadingContext = useContext(LoadingContext);
  
  if (!LoadingContext) {
    throw new Error('useLoading should be used inside LoadingProvider');
  }
  
  if (!loadingContext) {
    throw new Error('LoadingContext state is null. Make sure you have LoadingProvider as parent component.');
  }
  
  const [state, dispatch] = loadingContext;
  const { loading, activeLoading } = state;
  
  const startLoading = useCallback(() => {
    dispatch({
      type: START_LOADING,
    });
  }, [dispatch]);
  
const stopLoading = useCallback(() => {
    dispatch({
      type: STOP_LOADING,
    });
  }, [dispatch]);

  const incrementActiveLoading = useCallback(() => {
    dispatch({
      type: INCREMENT_ACTIVE_LOADING
    })
  }, [dispatch])
  
  const decrementActiveLoading = useCallback(() => {
    dispatch({
      type: DECREMENT_ACTIVE_LOADING
    })
  }, [dispatch])
  
  return {
    startLoading,
    stopLoading,
    incrementActiveLoading,
    decrementActiveLoading,
    activeLoading,
    loading
  };
};