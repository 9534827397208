import React, { useMemo } from 'react';
import { convertDateToJapanese, getTime, SplitDateRange } from '../../../utils/date';
import IconButton from '../../../components/ui/IconButton';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../components/icons';
import { DividerComponent } from '../../../components';
import { Timecard } from '../../../store/types/timecard';

interface DateProps {
  date: string;
  weekday: string;
}

const DateComponent: React.FC<DateProps> = ({ date, weekday }) => (
  <div>
    <h4 className="font-bold">{date}({weekday})</h4>
  </div>
);

interface TimecardEntryProps {
  timecard: Timecard | undefined;
}

const TimecardEntry: React.FC<TimecardEntryProps> = ({ timecard }) => (
  <div className={'flex justify-between items-end'}>
    {timecard ? (
      <>
        <p>出勤時間: {getTime(timecard.startTime || '')} - {timecard.endTime && !timecard.endTime.startsWith('0001') && getTime(timecard.endTime)}</p>
        <p>{timecard.totalHours ? `実績時間 : ${timecard.totalHours}` : 'ー'}</p>
      </>
    ) : (
      <p>ー</p>
    )}
  </div>
);

interface MobileProps {
  startDate: Date;
  endDate: Date;
  timecardList: Timecard[];
  memoizedSplitDateRange: SplitDateRange[];
  handleNextMonth: () => void;
  handlePreviousMonth: () => void;
  sumTotalHours: string;
  handleTimecardClick: (timecard: Timecard | null, date: Date | null) => void;
}

export const Mobile: React.FC<MobileProps> = ({
                                                startDate,
                                                endDate,
                                                timecardList,
                                                memoizedSplitDateRange,
                                                handlePreviousMonth,
                                                handleNextMonth,
                                                sumTotalHours,
                                                handleTimecardClick,
                                              }) => {
  const totalDays = useMemo(() => timecardList.filter((timecard) => timecard.endTime && !timecard.endTime.startsWith('0001')).length, [timecardList]);

  return (
    <div className={'md:hidden'}>
      {/* Header */}
      <div className="bg-primary-100/80 text-white p-4">
        <div className="text-lg sm:text-xl flex items-center justify-around">
          <h3 className="flex-1 flex flex-wrap">
            <span>{convertDateToJapanese(startDate)}</span>
            <span>〜</span>
            <span>{convertDateToJapanese(endDate)}</span>
          </h3>
          <div>
            <IconButton className="text-3xl sm:text-4xl" icon={<ChevronLeftIcon />}
                        onClick={handlePreviousMonth} />
            <IconButton className="text-3xl sm:text-4xl" icon={<ChevronRightIcon />}
                        onClick={handleNextMonth} />
          </div>
        </div>
        <div className="mt-2">
          {/* Summary Table */}
          <table className="table-auto w-full border-collapse">
            <thead>
            <tr>
              <th className="border-b border-b-accent-primary">出勤日数</th>
              <th className="border-b border-b-accent-primary">出勤時間</th>
            </tr>
            </thead>
            <tbody>
            <tr className="text-center">
              <td>{totalDays}日</td>
              <td>{sumTotalHours}時間</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Divider */}
      <DividerComponent />

      {/* Timecard Entries */}
      <div>
        {memoizedSplitDateRange.map((dateRange, index) => (
          <React.Fragment key={index}>
            <div className="w-full flex justify-center">
              <h3 className="text-xl w-fit font-bold p-4 border-b-2 border-b-accent-primary">{dateRange.month}月</h3>
            </div>
            <ul>
              {dateRange.days.map((date, index) => {
                const timecard = timecardList.find((timecard) => timecard.workDate === date.date);
                return (
                  <li
                    key={index}
                    className={`border-l-2 odd:bg-secondary-50 ${
                      timecard && timecard.startTime && timecard.endTime && !timecard.endTime.startsWith('0001')
                        ? 'border-l-accent-success'
                        : 'border-l-secondary-100'
                    } p-2 border-b last:border-b-0`}
                    onClick={() => {
                      handleTimecardClick(timecard || null, !timecard ? new Date(date.date) : null);
                    }}
                  >
                    {/* Date Component */}
                    <DateComponent date={date.day} weekday={date.weekday} />

                    {/* Timecard Entry Component */}
                    <TimecardEntry timecard={timecardList.find((timecard) => timecard.workDate === date.date)} />
                  </li>
                );
              })}
            </ul>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};