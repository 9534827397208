import React, { useMemo } from 'react';
import Day from './Day';

interface DayRowsProps {
  date: Date;
  kind: 'from' | 'to' | 'between';
  onDayClick?: (date: Date | null, active: boolean) => void;
  render?: (date: Date | null, active: boolean) => React.ReactNode;
}

export const DayRows: React.FC<DayRowsProps> = (props) => {
  const { date, kind, onDayClick, render } = props;
  
  const days = useMemo(() => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const days = [];
    
    days.push(Array.from({ length: firstDay.getDay() }, () => 0));
    
    for (let i = firstDay; i <= lastDay; i.setDate(i.getDate() + 1)) {
      if (days[days.length - 1].length === 7) {
        days.push([i.getDate()]);
      } else {
        days[days.length - 1].push(i.getDate());
      }
    }
    
    days[days.length - 1].push(...Array.from({ length: 6 - lastDay.getDay() }, () => 0));
    
    return days;
  }, [date])
  
  return (
    <>
      {days.map((week, weekIndex) => (
        <tr key={weekIndex}>
          {week.map((day, dayIndex) => {
            const isInactive = day === 0 || (kind === 'from' && day < date.getDate()) || (kind === 'to' && day > date.getDate()) || false;
            const cellDate = day ? new Date(date.getFullYear(), date.getMonth(), day) : null;
            return (
              <Day
                date={cellDate}
                onClick={() => {
                  onDayClick && onDayClick(cellDate, !isInactive);
                }}
                isInactive={isInactive}
                key={dayIndex}
                render={render ? () => { return render(cellDate, !isInactive)} : undefined}
              />
            );
          })}
        </tr>
      ))}
    </>
  );
};

export default DayRows;