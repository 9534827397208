import { SelectOptions } from '../../components/forms/Select';

type TimecardRevisionDetail = {
  start_time: string;
  end_time: string;
  workflow_id: string;
}

export type Timecard = {
  id?: number;
  startTime?: string;
  endTime?: string;
  totalHours?: number;
  notes?: string;
  revisionNumber?: number;
  revisionDetails?: TimecardRevisionDetail[];
  workDate?: string;
};


export const timecardKindChoices: SelectOptions[] = [{
  value: 'edit',
  label: '変更',
}, {
  value: 'create',
  label: '新規追加',
}, {
  value: 'delete',
  label: '削除',
}];