import React from 'react';

interface DayProps extends React.HTMLProps<HTMLTableCellElement>{
  date: Date | null;
  isInactive?: boolean;
  render?: () => React.ReactNode;
}

export const Day: React.FC<DayProps> = (props) => {
  const { date, render, isInactive = false, ...rest } = props;
  
  return(
    <td className={'text-center'} {...rest}>
      <div className={`flex justify-center items-center ${isInactive ? 'opacity-50' : ''}`}>
        {date ? render ? render() : date.getDate() : '-'}
      </div>
    </td>
  )
};

export default Day;