import React, { forwardRef } from 'react';
import IconButton from './IconButton';
import { XMarkIcon } from '../icons';

interface ModalProps extends React.HTMLProps<HTMLDialogElement> {
  isOpen: boolean;
  hasCloseBtn?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const Modal = forwardRef<HTMLDialogElement, ModalProps>((props, passedRef) => {
  const modalRef = React.useRef<HTMLDialogElement | null>(null);
  const {
    children,
    isOpen,
    onClose,
    hasCloseBtn,
    className,
    ref = modalRef,
    ...rest
  } = props;

  const [isModalOpen, setModalOpen] = React.useState(isOpen);

  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    setModalOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === 'Escape') {
      handleCloseModal();
    }
  };

  React.useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);
  
  return (
    <dialog 
      ref={passedRef || ref}
      onKeyDown={handleKeyDown}
      className={'py-8 px-1 sm:px-8 shadow shadow-accent-primary outline-0 rounded-sm relative backdrop:bg-primary-300/60 overflow-scroll overscroll-contain max-w-screen-lg' + (className ? ` ${className}` : '')}
      {...rest}
    >
      {
        hasCloseBtn && (
        <IconButton
          className="absolute top-2 right-2 text-primary-300 outline-0 text-xl"
          onClick={handleCloseModal}
          icon={<XMarkIcon />}
        />
        )
      }
      
      {children}
    </dialog>
  );
});

Modal.displayName = 'Modal';

export default Modal;