export type SplitDateRangeDays = {
  date: string;
  weekday: string;
  day: string;
  year: string;
}
export type SplitDateRange = {
  month: number;
  days: SplitDateRangeDays[];
}

export const JAPANESE_WEEKDAYS = ['日', '月', '火', '水', '木', '金', '土'];

export const splitDateRange = (startDate: string | Date, endDate: string | Date): SplitDateRange[] => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const splitDateRange: SplitDateRange[] = [];
  let currentMonth = start.getMonth() + 1;
  let currentDays: SplitDateRangeDays[] = [];
  for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
    if (d.getMonth() + 1 !== currentMonth) {
      splitDateRange.push({ month: currentMonth, days: currentDays });
      currentMonth = d.getMonth() + 1;
      currentDays = [];
    }
    currentDays.push({
      date: formatDate(d),
      weekday: JAPANESE_WEEKDAYS[d.getDay()],
      day: d.toLocaleString('en-US', { day: 'numeric' }),
      year: d.toLocaleString('en-US', { year: 'numeric' })
    });
  }
  splitDateRange.push({ month: start.getMonth() + 1, days: currentDays });
  return splitDateRange;
}

export const getTime = (date: string): string => {
  if (!date || date === '0001-01-01T00:00:00Z') {
    return '';
  }
  const d = new Date(date);
  return d.toLocaleString('ja-JP', { hour: '2-digit', minute: '2-digit', hour12: false });
}

export const getPreviousMonth = (date: Date | string, day: number): Date => {
  const d = new Date(date);
  d.setMonth(d.getMonth() - 1);
  d.setDate(day);
  return d;
}

export const getNextMonth = (date: Date | string, day: number): Date => {
  const d = new Date(date);
  d.setMonth(d.getMonth() + 1);
  d.setDate(day);
  return d;
}

export const convertDateToJapanese = (date: Date | string): string => {
  const d = new Date(date);
  return d.toLocaleString('ja-JP', { year: 'numeric', month: 'long', day: 'numeric' });
}

export const convertDateToJapaneseWithTime = (date: Date | string): string => {
  const d = new Date(date);
  return d.toLocaleString('ja-JP', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' });
}

const getStringOffset = (timezone = 'UTC', date = new Date()): string => {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
  const offset = (tzDate.getTime() - utcDate.getTime()) / 3600000;
  const sign = offset < 0 ? '-' : '+';
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset);
  const minutes = Math.floor((absOffset - hours) * 60);
  return `${sign}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}
export const generateISOString = (date: string, time: string, timezone: string): string => {
  // get the UTC time of timezone, for example, if timezone is 'Asia/Tokyo', the UTC time is 9 hours ahead of the local time
  const localTime = new Date(`${date}T${time}:00`);
  const offset = getStringOffset(timezone, localTime);
  return `${date}T${time}:00${offset}`;
}

export function formatDate(date = new Date()) {
  const year = date.toLocaleString('en-US', { year: 'numeric' });
  const month = date.toLocaleString('en-US', {
    month: '2-digit',
  });
  const day = date.toLocaleString('en-US', { day: '2-digit' });

  return [year, month, day].join('-');
}

// convert to input type="datetime-local" format
export function formatDatetimeLocal(date: string) {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const day = d.getDate().toString().padStart(2, '0');
  const hour = d.getHours().toString().padStart(2, '0');
  const minute = d.getMinutes().toString().padStart(2, '0');
  return `${year}-${month}-${day}T${hour}:${minute}`;
}