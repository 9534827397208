import { User } from './user';
import { AUTH_CLEAR, AUTH_SET, USER_SET } from '../constants/reducerConstants';

export type Auth = {
  isAuthenticated: boolean;
  token: string | null;
  user: User | null;
}

export type AuthActionType =
  | { type: typeof AUTH_SET; payload: Auth; }
  | { type: typeof AUTH_CLEAR; }
  | { type: typeof USER_SET; payload: User; };


export const defaultAuth: Auth = {
  isAuthenticated: false,
  token: null,
  user: null,
}

export const authReducer = (state: Auth = defaultAuth, action: AuthActionType): Auth => {
  switch (action.type) {
    case AUTH_SET:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_CLEAR:
      return {
        ...defaultAuth,
      };
    case USER_SET:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export type AuthLocationState = {
  prevLocation: string;
  message: string;
}