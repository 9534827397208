import React, { useEffect } from 'react';
import TimecardService from '../../../network/api/timecard';
import { useAuth } from '../../../hooks/useAuth';
import { Timecard } from '../../../store/types/timecard';
import useAuthAxios from '../../../hooks/useAuthAxios';

export const useTimecard = (startDate: Date, endDate: Date) => {
  const { token } = useAuth();
  const [timecardList, setTimecardList] = React.useState<Timecard[]>([]);
  const [selectedTimecard, setSelectedTimecard] = React.useState<Timecard | Date | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const axios = useAuthAxios();
  const sumTotalHours = React.useMemo(() => timecardList.reduce((acc, timecard) => {
      if (timecard.totalHours) {
        return acc + timecard.totalHours;
      }
      return acc;
    }
    , 0).toFixed(2).replace(/\.?0+$/, ''), [timecardList]);

  useEffect(() => {
    if (token) {
      const timecardService = new TimecardService(axios);
      timecardService
        .getAllTimecardByRangeDate(startDate.toLocaleDateString('ja-JP'), endDate.toLocaleDateString('ja-JP'))
        .then((data) => {
          setTimecardList(data);
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [startDate, endDate, token]);

  const handleTimecardClick = (timecard: Timecard | null, date: Date | null) => {
    if (timecard) {
      setSelectedTimecard(timecard);
    } else if (date) {
      setSelectedTimecard(date);
    }
    setIsModalOpen(true);
  };

  const handleTimecardModalClose = () => {
    setSelectedTimecard(null);
    setIsModalOpen(false);
  };

  return {
    startDate,
    endDate,
    timecardList,
    sumTotalHours,
    handleTimecardClick,
    selectedTimecard,
    handleTimecardModalClose,
    isModalOpen,
  };
};