import React, { useMemo } from 'react';
import MonthHeader from './MonthHeader';
import DayRows from './DayRows';
import { ICalendarProps } from './Calendar';

export const MonthRows: React.FC<ICalendarProps> = (props) => {
  const { from, to, onDayClick, render} = props;
  
  const months = useMemo(() => (to.getFullYear() - from.getFullYear()) * 12 + to.getMonth() - from.getMonth() + 1, [from, to])

  return (
    <>
      {Array.from({ length: months }, (_, index) => (
        <React.Fragment key={index}>
          <MonthHeader month={from.getMonth() + index + 1} />
          {
            index === 0
              ? <DayRows date={from} kind={'from'} onDayClick={onDayClick} render={render}/>
              : index === months - 1
              ? <DayRows date={to} kind={'to'} onDayClick={onDayClick} render={render}/>
              : <DayRows date={new Date(from.getFullYear(), from.getMonth() + index, 1)} kind={'between'} onDayClick={onDayClick} render={render}/>
          }
        </React.Fragment>
      ))}
    </>
  );
};

export default MonthRows;