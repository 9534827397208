import React from 'react';
import { getNextMonth, getPreviousMonth, splitDateRange } from '../utils/date';

export const useRangeDate = () => {
  const [startDate, setStartDate] = React.useState<Date>(() => {
    const today = new Date();
    if (today.getDate() >= 21) {
      today.setDate(21);
      return today;
    }
    return getPreviousMonth(today, 21);
  });
  const [endDate, setEndDate] = React.useState<Date>(getNextMonth(startDate, 20));
  

  const memoizedSplitDateRange = React.useMemo(() => splitDateRange(startDate, endDate), [startDate, endDate]);

  const handleNextMonth = () => {
    setStartDate(getNextMonth(startDate, 21));
    setEndDate(getNextMonth(endDate, 20));
  };

  const handlePreviousMonth = () => {
    setStartDate(getPreviousMonth(startDate, 21));
    setEndDate(getPreviousMonth(endDate, 20));
  };
  
  const handleDateChange = (year: number, month: number) => {
    const newDate = new Date(year, month - 1, 20);
    setStartDate(getPreviousMonth(newDate, 21));
    setEndDate(newDate);
  }

  
  
  return {
    startDate,
    endDate,
    memoizedSplitDateRange,
    handleNextMonth,
    handlePreviousMonth,
    handleDateChange
  };
}