import React from 'react';
import { CalendarPaginationComponent, DividerComponent, ModalComponent, TableComponent } from '../../../components';
import { useRangeDate } from '../../../hooks/useRangeDate';
import { convertDateToJapanese } from '../../../utils/date';
import { useAttendanceManagement } from '../hooks/useAttendanceManagement';
import { IColumnType } from '../../../components/ui/Table/Table';
import { Attendance } from '../../../store/types/attendance';
import { AttendanceDetail } from './AttendanceDetail';

export const AttendanceManagement: React.FC = () => {
  const {startDate, endDate, handleDateChange, memoizedSplitDateRange} = useRangeDate();
  const { attendance, handleAttendanceClick, selectedAttendance, handleTimecardChange, handleActionBtnClick } = useAttendanceManagement(startDate, endDate);
  
  const memorizedColumns: IColumnType<Attendance>[] = React.useMemo(() => [
    {
      key: 'fullName',
      title: '名前',
      render: (_, attendance) => (
        <span className={'text-accent-primary cursor-pointer'} onClick={() => handleAttendanceClick(attendance)}>{attendance.fullName}</span>
      )
    },
    {
      key: 'email',
      title: 'メールアドレス',
    },
    {
      key: 'totalWorkDays',
      title: '出勤日数',
      render: (_, attendance) => {
        if (!attendance.timecards) return 0;
        return attendance.timecards.filter(timecard => timecard.totalHours && timecard.totalHours > 0).length;
      }
    },
    {
      key: 'totalHours',
      title: '合計時間',
      render: (_, attendance) => {
        if (!attendance.timecards) return 0;
        return attendance.timecards.reduce((acc, timecard) => {
          if (timecard.totalHours) {
            return acc + timecard.totalHours;
          }
          return acc;
        }, 0).toFixed(2).replace(/\.?0+$/, '');
      }
    }
  ], [attendance]);
  
  return (
    <div>
      <CalendarPaginationComponent year={endDate.getFullYear()} month={endDate.getMonth() + 1} onMonthChange={handleDateChange}/>
      <DividerComponent />
      <div className={'w-full text-center text-xl text-primary-300 mb-2'}>{convertDateToJapanese(startDate)}〜{convertDateToJapanese(endDate)}</div>
      <TableComponent columns={memorizedColumns} data={attendance} />
      <ModalComponent isOpen={!!selectedAttendance} onClose={() => handleAttendanceClick(null)} className={'w-full'} hasCloseBtn={true}>
        {
          selectedAttendance && 
          <AttendanceDetail 
            attendance={selectedAttendance}
            startDate={startDate}
            endDate={endDate}
            memoizedSplitDateRange={memoizedSplitDateRange}
            handleTimecardChange={handleTimecardChange}
            handleActionBtnClick={handleActionBtnClick}
          />
        }
      </ModalComponent>
    </div>
  );
}