import React, { useEffect, useState } from 'react';

interface DateAnimationProps {
  showDate?: boolean;
  showTime?: boolean;
  showSeconds?: boolean;
  showWeekDay?: boolean;
  defaultDate?: Date;
}

const DateAnimation: React.FC<DateAnimationProps> = (props) => {
  if (props.defaultDate && props.showTime !== false) {
    throw new Error('defaultDate can only be used when showTime is set to false');
  }
  const { showDate = true, showSeconds = true, showTime = true, defaultDate = new Date(), showWeekDay = true } = props;
  const [date, setDate] = useState<Date>(defaultDate);

  useEffect(() => {
    if (showTime) {
      const dateChange = () => {
        setDate(new Date());
      };

      const interval = setInterval(dateChange, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  const renderWithZero = (value?: number) => {
    if (value == undefined) return;
    return value < 10 ? `0${value}` : value;
  };

  return (
    <div className="w-full flex flex-col items-center gap-2 text-primary-300">
      {
        showTime && (
          <div className={'text-4xl sm:text-7xl'}>
            <span className={'inline-block mr-0.5'}>
              {renderWithZero(date.getHours())}:{renderWithZero(date.getMinutes())}
            </span>
            {
              showSeconds && (
                <span className={'text-xl sm:text-3xl text-primary-100 inline-block text-left w-6'}>
                  {renderWithZero(date.getSeconds())}
                </span>
              )
            }
          </div>
        )
      }
      {
        showDate && (
          <div className={'text-2xl sm:text-4xl text-primary-100'}>
            <span>{date.getFullYear()}年{date.getMonth() + 1}月{date.getDate()}日</span>
            {
              showWeekDay && (
                <span className={'text-xl sm:text-2xl ml-1'}>({['日', '月', '火', '水', '木', '金', '土'][date.getDay()]})</span>
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default DateAnimation;