import React from 'react';
import { NavLink } from 'react-router-dom';


interface MenuLinkProps {
  path: string;
  children: React.ReactNode;
}

const MenuLink = ({ path, children }: MenuLinkProps) => {
  return (
    <li>
      <NavLink
        to={path}
        className={({isActive}) => 
          `cursor-pointer ${isActive ? 'border-b-2 border-b-accent-primary text-accent-active md:text-white' : 'hover:border-b-accent-primary hover:border-b-2'}`}
      >
        {children}
      </NavLink>
    </li>
  );

}

export default MenuLink;