import React, { lazy, Suspense } from 'react';
import { MENU_ITEMS } from '../../../store/constants/menuConstants';
import MenuLink from './MenuLink';
import { IconProps } from '../../../store/types/icon';

const Nav: React.FC = () => {
  return (
    <nav>
      <ul className={'flex gap-4 justify-around'}>
        {
          MENU_ITEMS.map((item, index) => {
            const IconElement:  React.LazyExoticComponent<React.ComponentType<IconProps>> = lazy(() => import(`../../icons/${item.icon}`));
            return (
              <MenuLink
                key={index}
                path={item.path}
              >
                <span className={'flex flex-col items-center'}>
                  <Suspense
                    fallback={<>-</>}
                  >
                    <IconElement className="text-2xl" />
                  </Suspense>
                          {item.title}
                </span>
              </MenuLink>
            );
          })
        }
      </ul>
    </nav>
  );
};

const BottomNavigation: React.FC = () => {
  return (
    <div className="fixed w-full bottom-0 p-2 bg-primary-100 text-white md:hidden">
      <Nav />
    </div>
  );
};

export default BottomNavigation;