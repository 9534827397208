import React, { forwardRef } from 'react';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, className, type = 'button', ...rest } = props;
  return (
    <button
      type={type}
      ref={ref}
      className={`py-2 px-4 rounded-md text-white disabled:opacity-30 ${className || 'bg-primary-200'}`}
      {...rest}
    >
      {children}
    </button>
  );
});

Button.displayName = 'Button';

export default Button;