import axios from 'axios';
import { BACKEND_URL } from '../store/constants/appConstants';

export default axios.create({baseURL: BACKEND_URL, withCredentials: true})

export const authAxios = axios.create({
  baseURL: BACKEND_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
})