import React from 'react';
import { NavigationComponent } from '../components';
import { Outlet } from 'react-router-dom';

const Base: React.FC = () => {
  
  return (
    <React.Fragment>
      <NavigationComponent />
      <main className="px-1 md:px-4 pt-4 pb-20 md:pb-4 bg-white">
        <Outlet />
      </main>
    </React.Fragment>
  );
}

export default Base;