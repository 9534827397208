import React from 'react';

interface DividerProps {
  isHorizontal?: boolean;
}

const Divider: React.FC<DividerProps> = ({isHorizontal = true}) => {
  return isHorizontal ? (
    <hr className="my-4 border-accent-primary" />
  ) : (
    <div className="h-full mx-4" />
  );
}

export default Divider;