import React from 'react';
import MonthRows from './MonthRows';

export interface ICalendarProps {
  from: Date;
  to: Date;
  onDayClick?: (date: Date | null, active: boolean) => void;
  render?: (date: Date | null, active: boolean) => React.ReactNode;
}

const Calendar: React.FC<ICalendarProps> = ({ from, to, onDayClick, render }) => {

  return (
    <table className={'w-full'}>
      <thead>
      <tr>
        {['日', '月', '火', '水', '木', '金', '土'].map((day, index) => (
          <th key={index} className={'text-center'}>{day}</th>
        ))}
      </tr>
      </thead>
      <tbody>
      <MonthRows from={from} to={to} onDayClick={onDayClick} render={render} />
      </tbody>
    </table>
  );
};

export default Calendar;