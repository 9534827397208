import React from 'react';

interface DetailItemProps {
  title: string;
  children: React.ReactNode;
}
const DetailItem: React.FC<DetailItemProps> = ({ title, children }) => {
  return (
    <>
      <dt className={'border-t border-r p-2 font-bold bg-secondary-75'}>{title}</dt>
      <dd className={'border-t p-2'}>{children}</dd>
    </>
  )
}

export default DetailItem;