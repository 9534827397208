import React from 'react';
import { IColumnType } from './Table';

interface TableHeaderProps<T> {
  columns: IColumnType<T>[];
}

export function TableHeader<T>({ columns }: TableHeaderProps<T>): React.ReactElement {
  return (
    <tr>
      {columns.map((column, index) => (
          <th
            key={`table-header-cell-${index}`}
            style={{ width: column.width }}
            className={'bg-primary-50 text-white text-left first:rounded-tl-xl last:rounded-tr-xl text-sm p-4 whitespace-nowrap'}
          >
            {column.title}
          </th>
        ),
      )}
    </tr>
  );
}