import { useCallback, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Auth } from '../store/types/auth';
import { AUTH_CLEAR, AUTH_SET, USER_SET } from '../store/constants/reducerConstants';
import { User } from '../store/types/user';

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  
  if (!AuthContext) {
    throw new Error('useAuth should be used inside AuthProvider');
  }
  
  if (!authContext) {
    throw new Error('AuthContext state is null. Make sure you have AuthProvider as parent component.');
  }
  
  const [auth, dispatch] = authContext;
  
  const {isAuthenticated, user, token} = auth;
  
  const setAuth = useCallback((payload: Auth) => {
    dispatch({
      type: AUTH_SET,
      payload: payload,
    });
  }, [dispatch]);
  
  const clearAuth = useCallback(() => {
    dispatch({
      type: AUTH_CLEAR,
    });
  }, [dispatch]);
  
  const setUser = useCallback((user: User) => {
    dispatch({
      type: USER_SET,
      payload: user,
    });
  }, [dispatch]);
  
  return {
    isAuthenticated,
    user,
    setAuth,
    clearAuth,
    token,
    setUser,
  };
}