export type WorkflowRequest = {
  timecardID?: number;
  startTime?: string;
  endTime?: string;
  workDate?: string;
  description?: string;
  title?: string;
  approverID?: string;
};

export type WorkflowUpdateRequest = {
  id: number;
  status: WorkflowStatus;
  history: WorkflowTimecardHistory;
}

export type TimecardWorkflowRequest = WorkflowRequest & {
  kind: 'edit' | 'create' | 'delete';
};

export type WorkflowApprovers = {
  id: string;
  fullName: string;
}

export type WorkflowStatus = 'pending' | 'approved' | 'rejected' | 'withdrawn' | 'returned';

export type WorkflowTimecardHistory = {
  work_date?: string;
  old_start_time?: string;
  start_time?: string;
  old_end_time?: string;
  end_time?: string;
  status: WorkflowStatus;
  timestamp?: string;
  notes?: string;
}

export type Workflow = {
  id: number;
  title: string;
  description: string;
  workflowType: string;
  requesterID: string;
  approverID: string;
  status: string;
  history: WorkflowTimecardHistory[];
  createdAt: string;
  approvedAt: string;
  approverName: string;
  requesterName: string;
}

export type WorkflowKind =
  'receivedAll'
  | 'receivedPending'
  | 'receivedApproved'
  | 'receivedReturned'
  | 'receivedRejected'
  | 'receivedWithdrawn'
  | 'sentAll'
  | 'sentPending'
  | 'sentApproved'
  | 'sentReturned'
  | 'sentRejected'
  | 'sentWithdrawn';

export const humanReadableStatus = (status: string) => {
  switch (status) {
    case 'pending':
      return '保留中';
    case 'approved':
      return '承認済み';
    case 'returned':
      return '差し戻し';
    case 'rejected':
      return '拒否';
    case 'withdrawn':
      return '取下げ';
    default:
      return status;
  }
};

export const humanReadableWorkflowType = (workflowType: string) => {
  const t = workflowType.split('-');
  if (t[0] === 'timecards') {
    if (t[1] === 'delete') {
      return '勤務時間削除';
    } else if (t[1] === 'create') {
      return '勤務時間作成';
    } else {
      return '勤務時間編集';
    }
  }
  return 'その他';
};