import React from 'react';
import { useUserManagement } from '../hooks/useUserManagement';
import { InputComponent, ModalComponent, TableComponent } from '../../../components';
import { TUser } from '../../../store/types/user';
import { IColumnType } from '../../../components/ui/Table/Table';
import Button from '../../../components/ui/Button';
import { InputCheckboxComponent } from '../../../components/forms';
import { formatDatetimeLocal } from '../../../utils/date';

export const UsersManagement: React.FC = () => {
  const {
    users,
    selectedUser,
    handleUserSelect,
    handleInputChange,
    handleSubmit,
  } = useUserManagement();

  const memoizedColumns: IColumnType<TUser>[] = React.useMemo(() => [
    {
      key: 'full_name',
      title: '氏名',
      render: (_, user) => <span className={'cursor-pointer text-accent-primary'} onClick={() => {
        handleUserSelect(user);
      }}>{`${user.last_name} ${user.first_name}`}</span>,
    },
    {
      key: 'email',
      title: 'メールアドレス',
    },
    {
      key: 'is_admin',
      title: '管理者',
      render: (_, user) => user.is_admin ? '管理者' : '一般',
    },
  ], [users]);

  return (
    <div>
      <TableComponent columns={memoizedColumns} data={users} />
      <ModalComponent isOpen={selectedUser !== null} onClose={() => handleUserSelect(null)}>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4">
            <InputComponent label={'姓'} value={selectedUser?.last_name || ''} name={'last_name'}
                            onChange={handleInputChange} />
            <InputComponent label={'名'} value={selectedUser?.first_name || ''} name={'first_name'}
                            onChange={handleInputChange} />
            <div className="col-span-2 flex items-center">
              <InputComponent label={'メールアドレス'} value={selectedUser?.email || ''} name={'email'}
                              onChange={handleInputChange} className={'flex-1'} />
              <div>
                <InputCheckboxComponent label={'特権管理者'} type={'checkbox'} onChange={handleInputChange} name={'is_super_admin'} checked={selectedUser?.is_super_admin || false} />
                <InputCheckboxComponent label={'管理者'} type={'checkbox'} checked={selectedUser?.is_admin || false}
                                        name={'is_admin'} onChange={handleInputChange} />
                <InputCheckboxComponent label={'有効'} type={'checkbox'} checked={selectedUser?.is_active || false}
                                        name={'is_active'} onChange={handleInputChange} />
              </div>
            </div>
            <div className={'col-span-2 flex-col'}>
              <InputCheckboxComponent label={'オンラインで出勤'} name={'can_use_website_timecard'} type={'checkbox'}
                                      checked={selectedUser?.can_use_website_timecard || false}
                                      onChange={handleInputChange} />
              {
                selectedUser?.can_use_website_timecard && (
                  <div className={'flex gap-4'}>
                    <InputCheckboxComponent label={'時間で打刻'} name={'website_timecard_by_duration'} type={'checkbox'}
                                            checked={selectedUser?.website_timecard_by_duration || false}
                                            onChange={handleInputChange} />
                    {
                      selectedUser?.website_timecard_by_duration && (
                        <div className="flex gap-4">
                          <InputComponent label={'開始時間'} type={'datetime-local'}
                                          value={selectedUser.website_timecard_from_time ? formatDatetimeLocal(selectedUser.website_timecard_from_time) : ''}
                                          name={'website_timecard_from_time'} onChange={handleInputChange} />
                          <InputComponent label={'終了時間'} type={'datetime-local'}
                                          value={selectedUser.website_timecard_to_time ? formatDatetimeLocal(selectedUser.website_timecard_to_time) : ''}
                                          name={'website_timecard_to_time'} onChange={handleInputChange} />
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>
            <InputComponent label={'カードID'} value={selectedUser?.card_id || ''} name={'card_id'}
                            className={'col-span-2'} disabled readOnly />
            <div className="flex justify-end gap-4 col-span-2">
              <Button type="button" onClick={() => handleUserSelect(null)}
                      className={'bg-secondary-100'}>キャンセル</Button>
              <Button type="submit">保存</Button>
            </div>
          </div>
        </form>
      </ModalComponent>
    </div>
  );
};