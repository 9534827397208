import React from 'react';
import { TabComponent } from '../../components';
import { ApplicationList } from './components/ApplicationList';
import { WorkflowKind } from '../../store/types/workflow';
import { WorkflowModal } from './components/WorkflowModal';
import { useWorkflow } from './hooks/useWorkflw';
import { useAuth } from '../../hooks/useAuth';

const applicationGeneralTabs = ['申請', '受けした申請'];

const generalTabTabs = [
  ['全て', '申請中', '承認済み', '差し戻し', '却下', '取り下げ'],
  ['全て', '申請中', '承認済み', '差し戻し', '却下', '取り下げ'],
];

const workflowListKinds: WorkflowKind[][] = [
  ['sentAll', 'sentPending', 'sentApproved', 'sentReturned',  'sentRejected', 'sentWithdrawn'],
  ['receivedAll', 'receivedPending', 'receivedApproved', 'receivedReturned', 'receivedRejected', 'receivedWithdrawn'],
];

const Application: React.FC = () => {
  const { user } = useAuth();
  const memorizedGeneralTabs = React.useMemo(() => {
    if (user?.isSuperAdmin || user?.isAdmin) {
      return applicationGeneralTabs;
    }
    return [applicationGeneralTabs[0]];
  }, [user]);
  return (
    <div>
      <TabComponent tabs={memorizedGeneralTabs} position={'center'}>
        {workflowListKinds.map((workflowListKind, index) => (
          <TabComponent tabs={generalTabTabs[index]} key={index}>
            {workflowListKind.map((kind, index) => (
              <ApplicationList key={index} kind={kind} />
            ))}
          </TabComponent>
        ))}
      </TabComponent>
    </div>
  );
};

export default Application;