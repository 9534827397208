import React from 'react';
import { Timecard } from '../../../store/types/timecard';
import { convertDateToJapanese, getTime } from '../../../utils/date';
import IconButton from '../../../components/ui/IconButton';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../components/icons';
import { CalendarComponent, DividerComponent, TableComponent } from '../../../components';
import { IColumnType } from '../../../components/ui/Table/Table';
import Button from '../../../components/ui/Button';

const timecardColumns: IColumnType<Timecard>[] = [
  {
    key: 'workDate',
    title: '日付',
  },
  {
    key: 'startTime',
    title: '出勤時間',
    render: (_, { startTime }) => <span>{getTime(startTime || '')}</span>,
  },
  {
    key: 'endTime',
    title: '退勤時間',
    render: (_, { endTime }) => <span>{endTime && !endTime.startsWith('0001') ? getTime(endTime) : 'ー'}</span>,
  },
  {
    key: 'breakTime',
    title: '休憩時間',
    render: (_, {endTime}) => <span>{endTime ? '1時間' : 'ー'}</span>,
  },
  {
    key: 'totalHours',
    title: '実績時間',
    render: (_, { totalHours, endTime }) => <span>{totalHours ? `${totalHours - (endTime ? 1 : 0)}時間` : 'ー'}</span>,
  },
];

interface DesktopProps {
  startDate: Date;
  endDate: Date;
  timecardList: Timecard[];
  handleNextMonth: () => void;
  handlePreviousMonth: () => void;
  sumTotalHours: string;
  handleTimecardClick: (timecard: Timecard | null, date: Date | null) => void;
}

export const Desktop: React.FC<DesktopProps> = ({
                                                             startDate,
                                                             endDate,
                                                             timecardList,
                                                             handlePreviousMonth,
                                                             handleNextMonth,
                                                             sumTotalHours,
                                                             handleTimecardClick,
                                                           }) => {
  const renderTimecardDate = (date: Date | null, active: boolean) => {
    if (!date) return 'ー';

    // find timecard by date
    const timecard = timecardList.find((timecard) => (
      convertDateToJapanese(timecard.workDate || '') === convertDateToJapanese(date)
    ));

    return timecard ? (
      <span onClick={() => {
        handleTimecardClick(timecard, null);
      }} className={'text-accent-primary font-bold underline cursor-pointer'}>{date.getDate()}</span>
    ) : active ?
      <span className={'cursor-pointer'} onClick={() => {
        handleTimecardClick(null, date);
      }}>{date.getDate()}</span>
      : <span>{date.getDate()}</span>;
  };
  
  const memorizedColumns = React.useMemo(() => {
    timecardColumns[0].render = (_, timecard) => (
      <span className={'cursor-pointer text-accent-primary'} onClick={() => {handleTimecardClick(timecard, null)}}>{convertDateToJapanese(timecard.workDate || '')}</span>
    );
    return timecardColumns;
  }, []);
  return (
    <div className={'w-full relative hidden md:flex justify-around gap-20 flex-col xl:flex-row'}>
      {/* Left side window with calendar */}
      <div className={'w-full xl:w-1/3 h-full bg-primary-100/80 text-white flex flex-row xl:flex-col'}>
        <div className={'p-4 flex-1 w-full'}>
          <div className={'flex justify-between items-center'}>
            <IconButton onClick={handlePreviousMonth} icon={<ChevronLeftIcon />} />
            <h3 className="flex-1 flex flex-wrap text-xl w-full justify-center">
              <span>{convertDateToJapanese(startDate)}</span>
              <span>〜</span>
              <span>{convertDateToJapanese(endDate)}</span>
            </h3>
            <IconButton onClick={handleNextMonth} icon={<ChevronRightIcon />} />
          </div>
          <DividerComponent />
          <div>
            {/* Calendar */}
            <CalendarComponent from={startDate} to={endDate} render={renderTimecardDate} />
          </div>
        </div>
        <DividerComponent />
        <div className={'p-4 flex-initial flex justify-around min-w-44 md:min-w-64'}>
          <div>
            <h3 className={'text-lg xl:text-xl'}>出勤日数</h3>
            <p
              className={'text-2xl xl:text-4xl'}>{timecardList.filter((timecard) => timecard.endTime && !timecard.endTime.startsWith('0001')).length}日</p>
          </div>
          <div>
            <h3 className={'text-lg xl:text-xl'}>出勤時間</h3>
            <p className={'text-2xl xl:text-4xl'}>{sumTotalHours}時間</p>
          </div>
        </div>
      </div>
      {/* Right side window with timecard list */}
      <div className={'flex-1'}>
        <div className={'flex-initial p-4 min-w-28 md:min-w-48'}>
          <Button type='button' onClick={() => {handleTimecardClick(null, null)}}>新規申請</Button>
        </div>

        <TableComponent columns={memorizedColumns} data={timecardList} />
      </div>
    </div>
  );
};