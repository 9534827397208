import React from 'react';
import IconButton from './IconButton';
import { ChevronLeftIcon, ChevronRightIcon } from '../icons';


const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

interface ICalendarPaginationProps {
  year: number;
  month: number;
  onMonthChange: (year: number, month: number) => void;
}

const CalendarPagination: React.FC<ICalendarPaginationProps> = (props) => {
  const { year, month, onMonthChange } = props;

  return (
    <div className="flex justify-center items-center">
      <IconButton icon={<ChevronLeftIcon className={'text-4xl text-primary-100'} />}
                  onClick={() => onMonthChange(year, month - 1)} />
      <div className={'text-secondary-100 flex gap-2 items-center'}>
        <div>{year}年</div>
        <div className={'hidden gap-2 text-2xl md:flex'}>
          {MONTHS.map((m, index) => (
            <span key={index} className={`${month === m ? 'rounded-full px-2 bg-primary-100 text-white' : 'text-primary-300'} cursor-pointer`}
                  onClick={() => onMonthChange(year, index + 1)}>{m}月</span>
          ))}
        </div>
        <div className={'flex gap-2 md:hidden text-primary-300'}>
          <select value={month} onChange={(e) => onMonthChange(year, parseInt(e.target.value))}>
            {MONTHS.map((m, index) => (
              <option key={index} value={m}>{m}月</option>
            ))}
          </select>
        </div>
      </div>
      <IconButton icon={<ChevronRightIcon className={'text-4xl text-primary-100'} />}
                  onClick={() => onMonthChange(year, month + 1)} />
    </div>
  );
};

export default CalendarPagination;