import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthLocationState } from '../store/types/auth';

interface ProtectedRouteProps {
  isAuthenticated: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthenticated }) => {
  const location = useLocation();
  
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      to="/auth"
      state={{
        prevLocation: location.pathname,
        message: 'ログインが必要です！',
      } as AuthLocationState}
    />
  )
};

export default ProtectedRoute;