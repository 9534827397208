import React from 'react';
import { Timecard } from '../../../store/types/timecard';
import { InputComponent, ModalComponent, SelectComponent, TextareaComponent } from '../../../components';
import { getTime } from '../../../utils/date';
import Button from '../../../components/ui/Button';
import { useTimecardModal } from '../hooks/useTimecardModal';

interface TimecardModalProps {
  timecard: Timecard | Date | null;
  onClose: () => void;
  isOpen: boolean;
}

const TimecardModal: React.FC<TimecardModalProps> = ({ isOpen, timecard, onClose }) => {
  const {
    state,
    kindChoices,
    handleInputChange,
    handleSubmit,
    approvers,
  } = useTimecardModal(timecard, onClose);

  return (
    <ModalComponent
      className={'w-full'}
      isOpen={isOpen}
      onClose={onClose}
      hasCloseBtn={true}
    >
      <div className={'flex flex-col md:flex-row-reverse w-full h-full gap-2'}>
        { /* History */}
        {!(timecard instanceof Date) && timecard  && (
          <div className={'w-1/3 p-2 relative'}>
            <div className={'text-center my-2'}>変更履歴</div>
            <ul className={'flex flex-col gap-2 divide-y'}>
              {timecard?.revisionDetails?.map((revision, index) => (
                <li key={index} className={'flex items-start gap-2 p-2'}>
                  <span>{index + 1}.</span>
                  <span>
                    <div>出勤時間：{getTime(revision.start_time)}</div>
                    <div>退勤時間：{getTime(revision.end_time)}</div>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <form className={'w-full p-2'} onSubmit={handleSubmit}>
          <fieldset className={'border-t flex flex-col gap-2'}>
            <legend className={'px-2 text-center'}>タイムカード</legend>
            <InputComponent
              label={'タイトル'}
              value={state?.title || ''}
              name={'title'}
              onChange={handleInputChange}
            />
            <SelectComponent
              options={kindChoices}
              value={state?.kind || ''}
              label={'申請種類'}
              name={'kind'}
              onChange={handleInputChange}
            />
            <InputComponent
              label={'日付'}
              type={'date'}
              value={state?.workDate || ''}
              name={'workDate'}
              onChange={handleInputChange}
              disabled={state?.kind !== 'create'}
            />
            <InputComponent
              label={'出勤時間'}
              type={'time'}
              value={state?.startTime || ''}
              name={'startTime'}
              onChange={handleInputChange}
              disabled={state?.kind === 'delete'}
            />
            <InputComponent
              label={'退勤時間'}
              type={'time'}
              value={state?.endTime || ''}
              name={'endTime'}
              onChange={handleInputChange}
              disabled={state?.kind === 'delete'}
            />
            <TextareaComponent
              label={'備考'}
              value={state?.description || ''}
              name={'description'}
              onChange={handleInputChange}
            />
            <SelectComponent
              options={approvers}
              labelKey={'fullName'}
              valueKey={'id'}
              value={state?.approverID || ''}
              label={'承認者'}
              name={'approverID'}
              onChange={handleInputChange}
            />
            <div className={'flex justify-end'}>
              <Button type={'submit'}>申請</Button>
            </div>
          </fieldset>
        </form>
      </div>
    </ModalComponent>
  );
};

export default TimecardModal;