import { useAuth } from './useAuth';
import { authAxios } from '../network/axios';
import { useEffect } from 'react';
import AuthService from '../network/api/auth';
import { useLoading } from './useLoading';

const useAuthAxios = () => {
  const { token, setAuth, clearAuth } = useAuth();
  const {stopLoading, startLoading, incrementActiveLoading, decrementActiveLoading} = useLoading();
  
  useEffect(() => {
    const requestIntercept = authAxios.interceptors.request.use(
      (config) => {
        if (!config.headers?.authorization) {
          config.headers.authorization = `Bearer ${token}`;
        }
        startLoading();
        incrementActiveLoading();
        return config;
      },
      (error) => Promise.reject(error)
    );
    
    const responseIntercept = authAxios.interceptors.response.use(
      (response) => {
        decrementActiveLoading()
        stopLoading();
        return response;
      },
      async (error) => {
        const previousRequest = error.config;
        
        decrementActiveLoading()
        stopLoading();
        
        if (error.response?.status === 401 && !previousRequest._retry) {
          previousRequest._retry = true;
          const authService = new AuthService();
          const data = await authService.checkSession();
          if (!data.token) {
            clearAuth();
            return Promise.reject(error);
          } else {
            setAuth({ isAuthenticated: true, ...data });
          }
          return authAxios(previousRequest);
        }
        
        return Promise.reject(error);
      }
    );
    
    return () => {
      authAxios.interceptors.request.eject(requestIntercept);
      authAxios.interceptors.response.eject(responseIntercept);
    };
  }, [token])
  
  return authAxios;
}

export default useAuthAxios;