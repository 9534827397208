import React, { useEffect, useState } from 'react';
import { Timecard, timecardKindChoices } from '../../../store/types/timecard';
import { formatDate, getTime } from '../../../utils/date';
import WorkflowService from '../../../network/api/workflow';
import { TimecardWorkflowRequest, WorkflowApprovers } from '../../../store/types/workflow';
import useAuthAxios from '../../../hooks/useAuthAxios';

export const useTimecardModal = (timecard: Timecard | Date | null, handleTimecardModalClose: () => void) => {
  const [state, setState] = useState<TimecardWorkflowRequest | null>(null);
  const [approvers, setApprovers] = useState<WorkflowApprovers[]>([]);
  const axios = useAuthAxios();
  const workflowService = new WorkflowService(axios);

  const kindChoices = React.useMemo(() => {
    if (timecard instanceof Date || timecard === null) {
      return timecardKindChoices.filter((choice) => choice.value === 'create');
    } else {
      return timecardKindChoices.filter((choice) => choice.value !== 'create');
    }
  }, [timecard]);

  const handleInputChange: React.FormEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> = (event) => {
    const { name, value } = event.currentTarget;
    setState((prevState) => {
      if (!prevState) return null;
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    workflowService.getApprovers()
      .then((data) => {
        setApprovers(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if ((timecard && timecard instanceof Date) || timecard === null) {
      setState({ kind: 'create', workDate: timecard ? formatDate(timecard) : '' });
    } else if (timecard) {
      setState({
        kind: 'edit',
        timecardID: timecard.id,
        startTime: getTime(timecard.startTime || ''),
        endTime: getTime(timecard.endTime || ''),
        workDate: timecard.workDate,
        description: '',
        title: '',
        approverID: '',
      });
    }
  }, [timecard]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (state) {
      workflowService.createTimecardWorkflow(state)
        .then(() => {
          handleTimecardModalClose();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return { state, kindChoices, handleInputChange, handleSubmit, approvers };
};