import React from 'react';
import { MENU_ITEMS } from '../../../store/constants/menuConstants';
import MenuLink from './MenuLink';
import IconButton from '../IconButton';
import { UserIcon } from '../../icons';
import { useAuth } from '../../../hooks/useAuth';
import { ButtonComponent, DividerComponent, InputComponent, ModalComponent } from '../../index';
import { User } from '../../../store/types/user';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import AuthService from '../../../network/api/auth';
import UserService from '../../../network/api/user';
import useAuthAxios from '../../../hooks/useAuthAxios';

const ProfileMenu: React.FC<{ user: User | null, onLogout: () => void, onProfileClick: () => void }> = ({ user, onLogout, onProfileClick }) => (
  <nav className="absolute right-0 top-12 bg-primary-100 p-4">
    <h2 className="text-white/60">{user?.lastName} {user?.firstName}</h2>
    <DividerComponent />
    <MenuList onLogout={onLogout} onProfileClick={onProfileClick} isAdmin={user?.isAdmin || false}/>
  </nav>
);

const MenuList: React.FC<{ onLogout: () => void, onProfileClick: () => void, isAdmin: boolean }> = ({onLogout, onProfileClick, isAdmin}) => (
  <ul className={'flex flex-col md:flex-row gap-2'}>
    {
      isAdmin && (
        <MenuLink path={'/dashboard'}>管理画面</MenuLink>
      )
    }
    <li onClick={onProfileClick} className="cursor-pointer">プロフィール</li>
    <li onClick={onLogout} className="cursor-pointer">ログアウト</li>
  </ul>
)

const Nav: React.FC = () => (
  <nav className="hidden md:block pt-2">
    <ul className="flex gap-4 justify-end text-lg">
      {MENU_ITEMS.map((item) => (
        <MenuLink key={item.path} path={item.path}>
          {item.title}
        </MenuLink>
      ))}
    </ul>
  </nav>
);

const AppBar: React.FC = () => {
  const { user, clearAuth, setUser } = useAuth();
  const profileMenuRef = React.useRef<HTMLDivElement>(null);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState<User | null>(null);
  const axios = useAuthAxios();
  
  const handleProfileMenuClick = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  useOnClickOutside(profileMenuRef, () => {
    setIsProfileMenuOpen(false);
  });

  const handleLogout = () => {
    const authService = new AuthService();
    authService.logout().then(() => {
      clearAuth();
    })
      .catch((err) => {
        alert(err.message);
      });
  };
  
  const handleProfileClick = () => {
    setIsModalOpen(true);
    setUserProfile(user);
  }
  
  const handleProfileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserProfile((prev) => prev === null ? prev : ({ ...prev, [name]: value }));
  }
  
  const handleProfileSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userProfile) return;
    
    const userService = new UserService(axios);
    userService.updateProfile(userProfile).then((data) => {
      setUser({...user, ...data});
      setIsModalOpen(false);
    })
      .catch((err) => {
        alert(err.message);
      });
  }
  
  return (
    <div className="bg-primary-200 text-white p-4 flex flex-col divide-y divide-primary-100 gap-2">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl">創建住販株式会社-勤怠管理</h1>
        <div ref={profileMenuRef} className="text-white md:hidden">
          <IconButton icon={<UserIcon />} onClick={handleProfileMenuClick} />
          {isProfileMenuOpen && <ProfileMenu user={user} onLogout={handleLogout} onProfileClick={handleProfileClick} />}
        </div>
        <div className="hidden md:flex divide-x divide-primary-100">
          <nav>
            <MenuList onLogout={handleLogout} onProfileClick={handleProfileClick} isAdmin={user?.isAdmin || user?.isSuperAdmin || false}/>
          </nav>
          <h2 className="text-white/60 ml-2 pl-2">{user?.lastName} {user?.firstName}</h2>
        </div>
      </div>
      <Nav />
      <ModalComponent 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        className={'w-full'}
      >
        <form onSubmit={handleProfileSubmit}>
          <div className="flex flex-col gap-4">
            <h2 className="text-2xl">プロフィール</h2>
            <InputComponent label={'姓'} value={userProfile?.lastName || ''} name={'lastName'} onChange={handleProfileChange}/>
            <InputComponent label={'名'} value={userProfile?.firstName || ''} name={'firstName'} onChange={handleProfileChange}/>
            <InputComponent label={'メールアドレス'} value={userProfile?.email || ''} disabled readOnly className={'opacity-50'} />
          </div>
          <div className="flex justify-end gap-4">
            <ButtonComponent type="button" className={'bg-secondary-100'} onClick={() => setIsModalOpen(false)}>キャンセル</ButtonComponent>
            <ButtonComponent type="submit">保存</ButtonComponent>
          </div>
        </form>
      </ModalComponent>
    </div>
  );
};

export default AppBar;
