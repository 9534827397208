import React, { useMemo } from 'react';
import { Attendance } from '../../../store/types/attendance';
import {
  convertDateToJapanese,
  getTime,
  SplitDateRange,
  SplitDateRangeDays,
} from '../../../utils/date';
import { IColumnType } from '../../../components/ui/Table/Table';
import { DividerComponent, TableComponent } from '../../../components';
import Button from '../../../components/ui/Button';
import { Timecard } from '../../../store/types/timecard';

interface IAttendanceDetailProps {
  attendance: Attendance;
  startDate: Date;
  endDate: Date;
  memoizedSplitDateRange: SplitDateRange[];
  handleTimecardChange: (e: React.ChangeEvent<HTMLInputElement>, workDate: string) => void;
  handleActionBtnClick: (timecard: Timecard) => void;
}

export const AttendanceDetail: React.FC<IAttendanceDetailProps> = (props) => {
  const {
    attendance,
    startDate,
    endDate,
    memoizedSplitDateRange,
    handleTimecardChange,
    handleActionBtnClick
  } = props;
  const allDays = useMemo(() => memoizedSplitDateRange.flatMap((month) => month.days), [memoizedSplitDateRange]);

  const memorizedColumns: IColumnType<SplitDateRangeDays>[] = React.useMemo(() => [
    {
      key: 'date',
      title: '日付',
      render: (_, { date, weekday }) => (
        convertDateToJapanese(date) + `(${weekday})`
      )
    },
    {
      key: 'startTime',
      title: '出勤時間',
      render: (_, { date }) => {
        const timecard = attendance?.timecards?.find((timecard) => timecard.workDate === date);
        return <input type={'time'} value={getTime(timecard?.startTime || '')} name='startTime' onChange={(e) => { handleTimecardChange(e, date) }} />
      }
    },
    {
      key: 'endTime',
      title: '退勤時間',
      render: (_, { date }) => {
        const timecard = attendance?.timecards?.find((timecard) => timecard.workDate === date);
        return <input type={'time'} value={getTime(timecard?.endTime || '')} name='endTime' onChange={(e) => { handleTimecardChange(e, date) }} />
      }
    },
    {
      key: 'breakTime',
      title: '休憩時間',
      render: (_, { date }) => {
        const timecard = attendance?.timecards?.find((timecard) => timecard.workDate === date);
        return timecard && getTime(timecard?.endTime || '') ? '1時間' : '';
      }
    },
    {
      key: 'totalHours',
      title: '合計時間',
      render: (_, { date }) => {
        const timecard = attendance?.timecards?.find((timecard) => timecard.workDate === date);
        return timecard && timecard.endTime && timecard.totalHours ? `${(timecard.totalHours - 1).toFixed(2).replace(/\.?0+$/, '')}時間` : '';
      }
    },
    {
      key: 'action',
      title: 'アクション',
      render: (_, { date }) => {
        const timecard = attendance?.timecards?.find((timecard) => timecard.workDate === date);
        return timecard ? <Button type={'button'} onClick={() => { handleActionBtnClick(timecard) }} className={'bg-accent-primary'}>{timecard.id ? '更新' : '追加'}</Button> : '';
      }
    }
  ], [allDays, attendance.timecards]);

  const handleCSVDownload = () => {
    const csv = [
      ['日付', '出勤時間', '退勤時間', '休憩時間', '合計時間']
    ];
    allDays.forEach((value) => {
      const timecard = attendance?.timecards?.find((t) => t.workDate === value.date);
      if (!timecard) {
        csv.push([convertDateToJapanese(value.date) + `(${value.weekday})`, '', '', '', '']);
        return;
      }
      csv.push([convertDateToJapanese(timecard.workDate || ''), getTime(timecard.startTime || ''), getTime(timecard.endTime || ''), '01:00', (timecard.totalHours ? timecard.totalHours - 1 : '').toString()]);
    });

    const csvContent = 'data:text/csv;charset=utf-8,' + csv.map(e => e.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${attendance?.fullName}の勤怠.csv`);
    document.body.appendChild(link);
    link.click();
  }

  return (
    <div>
      <h3 className={'text-xl text-primary-300 text-center w-full'}>{attendance?.fullName}</h3>
      <p className={'w-full text-center'}>{convertDateToJapanese(startDate)}~{convertDateToJapanese(endDate)}</p>
      <DividerComponent />
      <div className={'flex justify-end'}>
        <Button type={'button'} onClick={handleCSVDownload} className={'bg-accent-primary mb-1'}>CSVダウンロード</Button>
      </div>
      <TableComponent columns={memorizedColumns} data={allDays || []} />
    </div>
  )
};