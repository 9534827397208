import React from 'react';

interface MonthHeaderProps extends React.HTMLProps<HTMLTableCellElement> {
  month: number;
}

export const MonthHeader: React.FC<MonthHeaderProps> = (props) => {
  const { 
    month, 
    colSpan = 7, 
    className = 'text-center py-2 border-b border-b-accent-primary/40 text-accent-active',  
    ...rest 
  } = props;
  
  return (
    <tr>
      <td {...rest} colSpan={colSpan} className={className}>
        {month}月
      </td>
    </tr>);
};

export default MonthHeader;