import React, { useEffect, useMemo } from 'react';
import { TabComponent } from '../../components';
import { AttendanceManagement } from './components/AttendanceManagement';
import { UsersManagement } from './components/UsersManagement';
import { useAuth } from '../../hooks/useAuth';

const tabs = ['勤怠管理', 'ユーザー管理'];

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  const memorizedTabs = useMemo(() => {
    if (user && user.isSuperAdmin) {
      return tabs;
    }
    return [tabs[0]];
  }, [user]);
  return (
    <div>
      <TabComponent tabs={memorizedTabs} position={'center'}>
        <AttendanceManagement />
        <UsersManagement />
      </TabComponent>
    </div>
  );
};

export default Dashboard;