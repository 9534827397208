import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Workflow,
  WorkflowStatus,
  WorkflowTimecardHistory,
  WorkflowUpdateRequest,
} from '../../../store/types/workflow';
import WorkflowService from '../../../network/api/workflow';
import useAuthAxios from '../../../hooks/useAuthAxios';

export const useWorkflowModal = (workflow: Workflow | null, handleWorkflowUpdate: (workflow: Workflow, history: WorkflowTimecardHistory) => void) => {
  const [workflowTimecardHistory, setWorkflowTimecardHistory] = useState<WorkflowTimecardHistory | null>(null);
  const axios = useAuthAxios();
  const workflowService = new WorkflowService(axios);

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
    event.preventDefault();
    if (workflow) {
      const status = event.nativeEvent.submitter?.getAttribute('name');
      const history: WorkflowTimecardHistory = {
        ...workflowTimecardHistory,
        status: status as WorkflowStatus,
        timestamp: new Date().toISOString(),
      };
      const payload: WorkflowUpdateRequest = { id: workflow?.id, status: status as WorkflowStatus, history: history };
      workflowService.updateTimecardWorkflow(payload)
        .then(() => {
          handleWorkflowUpdate(workflow, history);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleInputChange: React.FormEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    const { name, value } = event.currentTarget;
    setWorkflowTimecardHistory((prevState) => {
      if (!prevState) return null;
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (workflow?.status === 'pending' || workflow?.status === 'returned') {
      // get last pending history
      const lastPendingHistory = [...workflow.history].filter((w) => w.status === 'pending').pop();
      if (lastPendingHistory) {
        setWorkflowTimecardHistory({
          ...lastPendingHistory,
          notes: '',
          timestamp: undefined,
        });
      }
    }
  }, [workflow]);

  return { handleSubmit, workflowTimecardHistory, handleInputChange };
};