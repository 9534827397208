import React from 'react';
import { TabNavigation } from './TabNavigation';
import { TabContent } from './TabContent';

interface TabProps {
  tabs: string[];
  defaultActiveIndex?: number;
  children: React.ReactNode[];
  position?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly';
}

export const Tab: React.FC<TabProps> = (props) => {
  const { tabs, defaultActiveIndex = 0, children, position } = props;
  const [activeTabIndex, setActiveTabIndex] = React.useState(defaultActiveIndex);
  
  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  }
  
  return (
    <div>
      <TabNavigation handleTabClick={handleTabClick} tabs={tabs} activeTabIndex={activeTabIndex} position={position} />
      <TabContent>{children[activeTabIndex]}</TabContent>
    </div>
  );
};

export default Tab;