import React from 'react';

interface DetailListProps extends React.HTMLAttributes<HTMLDListElement>{
  children: React.ReactNode;
}

const DetailList: React.FC<DetailListProps> = (props) => {
  const { children, className = 'grid grid-cols-2 border-x border-b' } = props;
  return (
    <dl {...props} className={className}>
      {children}
    </dl>
  )
}

export default DetailList;