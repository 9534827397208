import React from 'react';
import get from 'lodash.get';

import { IColumnType } from './Table';

interface TableRowCellProps<T> {
  item: T;
  column: IColumnType<T>;
  itemIndex: number;
}

export function TableRowCell<T>({ item, column, itemIndex }: TableRowCellProps<T>): React.ReactElement {
  const value = get(item, column.key);
  return (
    <td 
      key={column.key} 
      style={{ width: column.width }}
      className={'p-3 text-sm text-primary-300 whitespace-nowrap'}
    >
      {column.render ? column.render(column, item, itemIndex) : value}
    </td>
  );
}