import { EditUser, TUser, User } from '../../store/types/user';
import { Axios } from 'axios';
import { generateISOString } from '../../utils/date';

class UserService {
  authAxios: Axios;
  constructor(axios: Axios) {
    this.authAxios = axios;
  }
  
  async updateProfile(user: User) {
    const response = await this.authAxios.put('/users/profile',
      { lastName: user.lastName, firstName: user.firstName }
    );

    if (response.status !== 200) {
      throw new Error(response.data.message);
    }

    return response.data;
  }

  async getUsers() {
    const response = await this.authAxios.get('/users');

    if (response.status !== 200) {
      throw new Error(response.data.message);
    }

    return response.data;
  }

  async updateUser(user: TUser) {
    const from = user.website_timecard_from_time ? user.website_timecard_from_time.split('T') : [];
    const to = user.website_timecard_to_time ? user.website_timecard_to_time.split('T') : [];
    const data: EditUser = {
      'last_name': user.last_name,
      'first_name': user.first_name,
      'email': user.email,
      'is_admin': user.is_admin,
      'is_active': user.is_active,
      'is_super_admin': user.is_super_admin,
      'can_use_website_timecard': user.can_use_website_timecard,
      'website_timecard_by_duration': user.website_timecard_by_duration,
    }
    
    if (user.can_use_website_timecard && user.website_timecard_by_duration) {
      data['website_timecard_from_time'] = generateISOString(from[0], from[1], user.preferred_timezone);
      data['website_timecard_to_time'] = generateISOString(to[0], to[1], user.preferred_timezone);
    }
    const response = await this.authAxios.put(`/users/${user.id}`,
      data
    );

    if (response.status !== 200) {
      throw new Error(response.data.message);
    }

    return response.data;
  }
  
  async getUsersWithAttendance(startDate: string, endDate: string) {
    const response = await this.authAxios.post('/users/timecards',
      {startDate, endDate}
    )
    
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
  
  async userStatus() {
    const response = await this.authAxios.get('/users/status');
    
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
}

export default UserService;