import React from 'react';

interface TabContentProps {
  children: React.ReactNode;
}

export const TabContent: React.FC<TabContentProps> = (props) => {
  const { children} = props;

  return (
    <div className={'p-2 md:p-4 my-1'}>
      {children}
    </div>
  );
}