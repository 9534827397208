import React from 'react';
import { Label } from './Label';

export type SelectOptions = {
  [key: string | 'label' | 'value']: any;
}

interface SelectProps<T extends SelectOptions> extends React.HTMLProps<HTMLSelectElement> {
  options: T[];
  label: string;
  labelKey?: keyof T;
  valueKey?: keyof T;
}

const Select = React.forwardRef( function Select<T extends SelectOptions>(
  props: SelectProps<T>, 
  forwarderRef: React.Ref<HTMLSelectElement>
)  {
  const { 
    options,
    name,
    label,
    id,
    className,
    labelKey = 'label',
    valueKey = 'value',
    ...rest
  } = props;
  
  const htmlFor = id ? id : `id_select_${name}`;
  
  return (
    <Label htmlFor={htmlFor} label={label} className={className}>
      <select {...rest} id={htmlFor} name={name} className={'outline-0 w-full'} ref={forwarderRef}>
        <option value={''} disabled={true}>Select...</option>
        {options.map((option, index) => (
          <option key={index} value={option[valueKey]}>{option[labelKey]}</option>
        ))}
      </select>
    </Label>
  );
});

export default Select;