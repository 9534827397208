import React, { useEffect } from 'react';
import { Workflow, WorkflowKind, WorkflowTimecardHistory } from '../../../store/types/workflow';
import WorkflowService from '../../../network/api/workflow';
import useAuthAxios from '../../../hooks/useAuthAxios';

export const useWorkflow = (workflowType: WorkflowKind) => {
  const [workflows, setWorkflows] = React.useState<Workflow[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = React.useState<Workflow | null>(null);
  const axios = useAuthAxios();
  const workflowService = new WorkflowService(axios);

  useEffect(() => {
    workflowService.getWorkflowList(workflowType)
      .then((data) => setWorkflows(data))
      .catch((error) => console.error(error));
  }, []);

  const handleModalClose = () => {
    setSelectedWorkflow(null);
  };

  const handleWorkflowClick = (workflow: Workflow) => {
    setSelectedWorkflow(workflow);
  };

  const handleWorkflowUpdate = (workflow: Workflow, history: WorkflowTimecardHistory) => {
    if (workflowType.endsWith('All')) {
      setWorkflows((prev) => prev.map((w) => w.id === workflow.id ? {
        ...w,
        status: history.status,
        history: [...w.history, history],
      } : w));
    } else {
      setWorkflows((prev) => prev.filter((w) => w.id !== workflow.id));
    }
    setSelectedWorkflow((prev) => prev?.id === workflow.id ? {
      ...prev,
      status: history.status,
      history: [...prev.history, history],
    } : prev);
  };

  return {
    workflows,
    selectedWorkflow,
    handleModalClose,
    handleWorkflowClick,
    handleWorkflowUpdate,
  };
};