import { Axios } from 'axios';
import { Timecard } from '../../store/types/timecard';

class TimecardService {
  authAxios: Axios;
  constructor(axios: Axios) {
    this.authAxios = axios;
  }
  async getAllTimecardByRangeDate(startDate: string, endDate: string) {
    const response = await this.authAxios.post('/timecard', {
        startDate,
        endDate,
      });

    if (response.status !== 200) {
      throw new Error(response.data.message);
    }

    return response.data;
  }

  async getTimecardByID(id: number) {
    const response = await this.authAxios.get(`/timecard/${id}`);

    if (response.status !== 200) {
      throw new Error(response.data.message);
    }

    return response.data;
  }
  
  async updateTimecard(timecard: Timecard, userId: string) {
    const data = {
      userId: userId,
      startTime: timecard.startTime,
      endTime: timecard.endTime,
      id: timecard.id || 0,
    }
    const response = await this.authAxios.put('/timecard', data);
    
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
  
  async startTimecard() {
    const response = await this.authAxios.post('/timecard/start', {timezone: 'Asia/Tokyo'});
    
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
  
  async endTimecard() {
    const response = await this.authAxios.post('/timecard/end');
    
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    
    return response.data;
  }
}

export default TimecardService;