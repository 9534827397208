import React from 'react';
import AppBar from './AppBar';
import BottomNavigation from './BottomNavigation';

const Navigation: React.FC = () => {
  return (
    <header className='sticky top-0 border-b-2 border-b-accent-primary z-10'>
      <AppBar />
      <BottomNavigation />
    </header>
  );
}

export default Navigation;