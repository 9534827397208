import React, { useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthLocationState } from '../store/types/auth';
import { ButtonComponent } from '../components';
import AuthService from '../network/api/auth';
import { BACKEND_URL } from '../store/constants/appConstants';

const Auth: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { prevLocation } = location.state as AuthLocationState || { prevLocation: '/', message: '' };
  const { setAuth, isAuthenticated } = useAuth();
  const authService = new AuthService();

  useEffect(() => {
    if (!isAuthenticated) {
      authService.checkSession()
        .then((data) => {
          setAuth({ isAuthenticated: true, ...data });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(prevLocation);
    }
  }, [navigate, prevLocation, isAuthenticated]);

  const handleClick = () => {
    window.location.href = `${BACKEND_URL}/session/login`;
  };

  return (
    <div className={'h-screen w-screen flex justify-center items-center'}>
      <ButtonComponent color="inherit" type="button" className={'bg-primary-100'} onClick={handleClick}>
        <div className={'flex items-center justify-center p-4 text-xl'}>
          <svg style={{ marginRight: '10px' }} aria-hidden="true" className="native svg-icon iconGoogle" width="18"
               height="18" viewBox="0 0 18 18">
            <path fill="#4285F4"
                  d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18Z"></path>
            <path fill="#34A853"
                  d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17Z"></path>
            <path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07Z"></path>
            <path fill="#EA4335"
                  d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3Z"></path>
          </svg>
          Googleでログイン
        </div>
      </ButtonComponent>
    </div>
  );
};

export default Auth;