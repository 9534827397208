import React from 'react';

interface InputCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({ label, className, ...props }) => (
  <div className="flex items-center">
    <input type="checkbox" {...props} className={`mr-2 accent-accent-primary w-5 h-5 ${className || ''}`} />
    <label>{label}</label>
  </div>
);

export default InputCheckbox;