import React, { useEffect, useState } from 'react';
import { Attendance } from '../../../store/types/attendance';
import UserService from '../../../network/api/user';
import { useAuth } from '../../../hooks/useAuth';
import useAuthAxios from '../../../hooks/useAuthAxios';
import { Timecard } from '../../../store/types/timecard';
import { generateISOString } from '../../../utils/date';
import TimecardService from '../../../network/api/timecard';

export const useAttendanceManagement = (from: Date, to: Date) => {
  const [attendance, setAttendance] = useState<Attendance[]>([]);
  const [selectedAttendance, setSelectedAttendance] = useState<Attendance | null>(null);
  const { token } = useAuth();
  const axios = useAuthAxios();
  const userServices = new UserService(axios);
  
  useEffect(() => {
    if (token) {
      userServices.getUsersWithAttendance(from.toLocaleDateString('ja-JP'), to.toLocaleDateString('ja-JP'))
        .then((data) => {
          setAttendance(data);
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }, [from, to]);
  
  const handleAttendanceClick = (attendance: Attendance | null) => {
    setSelectedAttendance(attendance);
  }
  
  const handleTimecardChange = (e: React.ChangeEvent<HTMLInputElement> , workDate: string) => {
    if (selectedAttendance) {
      const {name, value} = e.target;
      const timecard = selectedAttendance.timecards?.find((timecard) => timecard.workDate === workDate);
      let timecards: Timecard[] | undefined = [];
      console.log(value, workDate);
      if (timecard) {
        timecards = selectedAttendance.timecards?.map((timecard) => {
          if (timecard.workDate === workDate) {
            return name === 'startTime' || name === 'endTime' ? {
              ...timecard,
              [name]: generateISOString(workDate, value, 'Asia/Tokyo')
            } : {
              ...timecard,
              [name]: value
            }
          }
          return timecard;
        });
      } else {
        timecards = selectedAttendance.timecards ? 
          [...selectedAttendance.timecards, 
            {workDate, [name]: name === 'startTime' || name === 'endTime' ? generateISOString(workDate, value, 'Asia/Tokyo') : value}
          ] 
          : [{workDate, [name]: name === 'startTime' || name === 'endTime' ? generateISOString(workDate, value, 'Asia/Tokyo') : value}];
      }
      
      setSelectedAttendance({
        ...selectedAttendance,
        timecards
      });
    }
  }
  
  const handleActionBtnClick = (timecard?: Timecard) => {
    const timecardService = new TimecardService(axios);
    console.log(timecard, selectedAttendance?.userID);
    if (timecard && selectedAttendance?.userID) {
      timecardService.updateTimecard(timecard, selectedAttendance.userID)
        .then((data) => {
          const att = {...selectedAttendance};
          att.timecards = att.timecards?.map((tc) => tc.workDate === data.workDate ? data : tc);
          setSelectedAttendance(att);
          setAttendance((prev) => prev.map((a) => a.userID === att.userID ? att : a));
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  }
  
  return {
    attendance,
    selectedAttendance,
    handleAttendanceClick,
    handleTimecardChange,
    handleActionBtnClick
  };
};